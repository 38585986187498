import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    setDataForm,
    setDataModalPublicationVisibility,
    setDataPublicationItemDetail
} from "../../reducers/data.reducer";
import {FormPublicationModal} from "./form-publication.modal";

const PublicationComponent = () => {
    const dispatch = useDispatch()
    const {dataForm} = useSelector(state => state.dataReducer)
    const columns = ['socialMedia', 'socialMediaAccount', 'scopeOfWorkDetail', 'quantity', 'publicationPeriod']

    const onEdit = (item) => {
        dispatch(setDataPublicationItemDetail(item))
        dispatch(setDataModalPublicationVisibility(true))
    }

    const onDelete = (item, index) => {
        let newData = dataForm.scopeOfWorkList.filter((item, i) => {
            if (i !== index) {
                return true
            }
        })

        dispatch(setDataForm({...dataForm, ...{scopeOfWorkList: newData}}))
    }

    const openModal = () => {
        dispatch(setDataPublicationItemDetail({}))
        dispatch(setDataModalPublicationVisibility(true))
    }

    return (
        <div className={"space-y-5"}>
            <div className={"flex"}>
                <div className={"button-blue ml-28 cursor-pointer"} onClick={openModal}>Tambah Data
                </div>
            </div>
            <div className={"col items-center"}>
                <table>
                    <thead className={"bg-blue-100 font-NunitoSans-Bold text-fadedBlue h-8"}>
                    <tr>
                        <th className={"w-40 text-center"}>Media/Media Sosial</th>
                        <th className={"w-40 text-center"}>Nama Media/Akun</th>
                        <th className={"w-40 text-center"}>Scope of Work</th>
                        <th className={"w-40 text-center"}>Kuantitas</th>
                        <th className={"w-40 text-center"}>Periode Publikasi</th>
                        <th className={"w-40 text-center"}>Action</th>
                    </tr>
                    </thead>
                    <tbody className={"divide-y"}>
                    {
                        dataForm.scopeOfWorkList.map((item, index) => (
                            <tr key={index} >
                                {
                                    columns.map((i, idx) => <td className={`text-center py-3 ${idx === 0 && 'capitalize'}`}
                                                                key={idx + index}>{item[i]}</td>)
                                }
                                <td>
                                    <div className={"row items-center justify-center space-x-5"}>
                                        <div className={"cursor-pointer font-bold text-darkSeaFoam"}
                                             onClick={() => onEdit(item, index)}>Edit
                                        </div>
                                        <div className={"cursor-pointer font-bold text-pastelRed"}
                                             onClick={() => onDelete(item, index)}>Delete
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
            <FormPublicationModal/>
        </div>
    )
}

export default PublicationComponent
