import {combineReducers, configureStore} from "@reduxjs/toolkit";
import userReducer from "./user.reducer";
import dataReducer from "./data.reducer";

const rootReducer = combineReducers({
    userReducer,
    dataReducer
})

const store = configureStore({reducer: rootReducer})

export default store
