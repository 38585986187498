import React from "react";

const MainDashboardComponent = (props) => {
    const {content} = props
    return (
        <div className={"w-screen h-screen flex justify-center items-center font-Montserrat-Regular text-sm"}>
            {content}
        </div>
    )
}

export default MainDashboardComponent
