import {createSlice} from '@reduxjs/toolkit'
import {formModels} from "../models/form.models";

export const dataSlice = createSlice({
    name: 'data',
    initialState: {
        dataForm: {...formModels},
        publicationItemDetail: {},
        termOfPaymentDetail: {},
        modalPublicationVisibility: false,
        modalTermPaymentVisibility: false,
        loading: false,
        modalSuccessVisibility: false
    },
    reducers: {
        setDataReducer: (state, action) => {
            state.dataForm = {...state.dataForm, ...action.payload}
        },
        setDataForm: (state, action) => {
            state.dataForm = action.payload
        },
        setDataPublicationItemDetail: (state, action) => {
            state.publicationItemDetail = action.payload
        },
        setDataModalPublicationVisibility: (state, action) => {
            state.modalPublicationVisibility = action.payload
        },
        setDataModalTermPaymentVisibility: (state, action) => {
            state.modalTermPaymentVisibility = action.payload
        },
        setDataTermOfPaymentDetail: (state, action) => {
            state.termOfPaymentDetail = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setModalSuccessVisibility: (state, action) => {
            state.modalSuccessVisibility = action.payload
        },
    },
})

export const {
    setDataForm,
    setDataPublicationItemDetail,
    setDataModalPublicationVisibility,
    setDataModalTermPaymentVisibility,
    setDataTermOfPaymentDetail,
    setModalSuccessVisibility,
    setLoading,
    setDataReducer
} = dataSlice.actions
const dataReducer = dataSlice.reducer

export default dataReducer
