import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {SelectComponent} from "./select.component";
import {setDataReducer} from "../reducers/data.reducer";

const InputComponent = ({item, page}) => {
    const dispatch = useDispatch()
    const {dataForm, publicationItemDetail, termOfPaymentDetail} = useSelector(state => state.dataReducer)

    const saveChangesToReducer = (value) => {
        dispatch(setDataReducer({[item.key]: value}))
    }

    switch (item.type) {
        case 'term':
            return (
                <div className={"row items-center space-x-4"}>
                    <label htmlFor={item.key} className={"w-52 text-dark font-semibold"}>{item.label}</label>
                    <div className={"col w-72"}>
                        <input id={item.key}
                               disabled={item?.disabled}
                               placeholder={item.placeholder}
                               defaultValue={termOfPaymentDetail[item.key]}
                               type={item.key === 'paymentState' ? 'text' : 'number'}
                               className={"border border-green-grey rounded-md w-fit py-2 px-3 w-full focus:outline-green-sage focus:shadow-outline"}
                               required={item.required}/>
                        <p className={"text-red-600 text-[10px]"}>{item.errorText}</p>
                    </div>
                </div>
            )
        case 'textarea':
            return (
                <div className={"row space-x-4"}>
                    <label htmlFor={item.key} className={"w-52 text-dark font-semibold"}>{item.label}</label>
                    <div className={"col w-72"}>
                    <textarea id={item.key} rows="4"
                              placeholder={item.placeholder}
                              onChange={(e) => saveChangesToReducer(e.target.value)}
                              defaultValue={page === 'publication' ? publicationItemDetail[item.key] : dataForm[item.key]}
                              className={"border border-green-grey rounded-md w-fit py-2 px-3 w-full focus:outline-green-sage focus:shadow-outline"}
                              required={item.required}/>
                        <p className={"text-red-600 text-[10px]"}>{item.errorText}</p>
                    </div>
                </div>
            )
        case 'select':
            return (
                <div className={"row items-center space-x-4"}>
                    <label htmlFor={item.key} className={"w-52 text-dark font-semibold"}>{item.label}</label>
                    <div className={"col w-72"}>
                        <SelectComponent item={item}/>
                        <p className={"text-red-600 text-[10px]"}>{item.errorText}</p>
                    </div>
                </div>
            )
        default:
            return (
                <div className={"row items-center space-x-4"}>
                    <label htmlFor={item.key} className={"w-52 text-dark font-semibold"}>{item.label}</label>
                    <div className={"col w-72"}>
                        <input id={item.key}
                               defaultValue={page === 'publication' ? publicationItemDetail[item.key] : dataForm[item.key]}
                               type={item.type}
                               onChange={(e) => saveChangesToReducer(e.target.value)}
                               placeholder={item.placeholder}
                               className={"border border-green-grey rounded-md w-fit py-2 px-3 w-full focus:outline-green-sage focus:shadow-outline"}
                               required={item.required}/>
                        <p className={"text-red-600 text-[10px]"}>{item.errorText}</p>
                    </div>
                </div>
            )
    }
}

export default InputComponent
