import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        userDetail: {},
    },
    reducers: {
        setUserDetail: (state, action) => {
            state.userDetail = action.payload
        }
    },
})

export const { setUserDetail } = userSlice.actions
const userReducer = userSlice.reducer

export default userReducer
