import React from "react";
import InputComponent from "../../components/input.component";
import TermOfPaymentComponent from "./term-of-payment.component";
import {setDataModalTermPaymentVisibility, setDataTermOfPaymentDetail} from "../../reducers/data.reducer";
import {useDispatch, useSelector} from "react-redux";
import {SELECT_BANK_OBJECT} from "../../utils/constant";

const PeriodTimeComponent = ({formList}) => {
    const dispatch = useDispatch()
    const {dataForm} = useSelector(state => state.dataReducer)

    const addData = () => {
        let termCount = dataForm.termOfPaymentList.length + 1
        dispatch(setDataTermOfPaymentDetail({termin: termCount, payAmount: '', paymentState: ''}))
        dispatch(setDataModalTermPaymentVisibility(true))
    }

    return (
        <div className={"px-5"}>
            <div className={"grid grid-cols-2 gap-5"}>
                <div>
                    <p className={"text-base text-dark font-bold mb-3"}>Detail Kerja Sama</p>
                    <div className={"space-y-5"}>
                        <InputComponent item={SELECT_BANK_OBJECT}/>
                        {
                            formList.map((item, index) => <InputComponent item={item} key={index}/>)
                        }

                    </div>
                </div>
                <div className={"space-y-3"}>
                    <p className={"text-base text-dark font-bold"}>Detail Termin</p>
                    <div className={"flex"}>
                        <div className={"button-blue cursor-pointer"} onClick={addData}>Tambah Data Termin</div>
                    </div>
                    <TermOfPaymentComponent/>
                </div>
            </div>
        </div>
    )
}

export default PeriodTimeComponent
