import {getApiKeySheetURL} from "../utils/general-variable";

export const setAgreementDataApi = (brand, bodyRequest, sheetName) => {
    let {url, apiKey} = getApiKeySheetURL(brand)
    let completeUrl = `https://sheet.best/api/sheets/${url}/tabs/${sheetName}`

    return new Promise((resolve, reject) => {
        fetch(completeUrl,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": apiKey
                },
                body: JSON.stringify(bodyRequest),
            })
            .then((r) => r.json())
            .then((data) => {
                resolve(data)
            })
            .catch((error) => {
                reject(error)
            });
    })
}
