import React from "react";
import InputComponent from "../../components/input.component";
import {SELECT_DIVISION_OBJECT} from "../../utils/constant";

const KolFormComponent = ({formList}) => {
    return (
        <div className={"col items-center mt-5 space-y-5"}>
            <InputComponent item={SELECT_DIVISION_OBJECT}/>
            {
                formList.map((item, index) => <InputComponent item={item} key={index}/>)
            }
        </div>
    )
}

export default KolFormComponent
