import bankList from "./list-bank.json"

export const KOL_FORM_LIST = [
    {
        label: 'Nama KOL',
        key: 'nameOfKOL',
        type: 'text',
        required: true,
        placeholder: 'Cth: Refal Hady'
    }, {
        label: 'NIB/NIK',
        key: 'identityCard',
        type: 'number',
        required: false,
        placeholder: 'Cth: 1220620202020001'
    }, {
        label: 'Submit Link File NIB/NIK',
        key: 'identityCardImage',
        type: 'text',
        required: false,
        placeholder: 'Cth: https://drive.google.com/file/d/1KgQ'
    }, {
        label: 'Alamat KOL',
        key: 'addressKol',
        type: 'textarea',
        required: true,
        placeholder: 'Cth: Jl. monjali No.99, gemawang'
    }
]

export const REPRESENTATIVE_FORM_LIST = [
    {
        label: 'Nama Penandatangan',
        key: 'signerName',
        type: 'text',
        required: true,
        placeholder: 'Cth: Puja Wardani'
    }, {
        label: 'Jabatan',
        key: 'signerRole',
        type: 'text',
        required: true,
        placeholder: 'Cth: IT Director'
    }, {
        label: 'Nama Badan Usaha / Organisasi',
        key: 'organizationName',
        type: 'text',
        required: true,
        placeholder: 'Cth: PT. AVO Innovation Technology'
    }, {
        label: 'Alamat',
        key: 'organizationAddress',
        type: 'textarea',
        required: true,
        placeholder: 'Cth: Jl. Monjali No.99, Gemawang'
    }, {
        label: 'NIB / NIK',
        key: 'organizationIdentityNumber',
        type: 'number',
        required: true,
        placeholder: 'Cth: 7712345623512'
    }, {
        label: 'Submit File NIB / NIK',
        key: 'organizationIdentityImage',
        type: 'text',
        required: true,
        placeholder: 'Cth: https://drive.google.com/file/d/1KgQ'
    }, {
        label: 'Email Penandatangan',
        key: 'signerEmail',
        type: 'text',
        required: true,
        placeholder: 'Cth: puja@avo.co.id'
    }, {
        label: 'No. HP',
        key: 'signerPhoneNumber',
        type: 'number',
        required: true,
        placeholder: 'Cth: 085208520852'
    },
]

export const PERIOD_FORM_LIST = [
    {
        label: 'Nomor Rekening',
        key: 'bankAccount',
        type: 'number',
        required: true,
        placeholder: 'Cth: 4675273281'
    }, {
        label: 'Nama Pemilik Rekening',
        key: 'bankAccountOwner',
        type: 'text',
        required: true,
        placeholder: 'Cth: Refal Hady'
    }, {
        label: 'NPWP',
        key: 'NPWPNumber',
        type: 'text',
        required: false,
        placeholder: 'Cth: 12345678901213'
    }, {
        label: 'Submit Link File NPWP',
        key: 'NPWPImageLink',
        type: 'text',
        required: false,
        placeholder: 'Cth: 3000000'
    }, {
        label: 'Jangka Waktu Kerja Sama Dimulai',
        key: 'contractStartDate',
        type: 'month',
        required: true,
    }, {
        label: 'Jangka Waktu Kerja Sama Berakhir',
        key: 'contractEndDate',
        type: 'month',
        required: true
    }, {
        label: 'Harga Kerja Sama',
        key: 'contractPrice',
        type: 'number',
        required: true,
        placeholder: 'Cth: 3000000'
    },
]

export const PUBLICATION_FORM_LIST = [
    {
        label: 'Nama Media/Akun',
        key: 'socialMediaAccount',
        type: 'text',
        required: true,
        placeholder: 'Cth: refalhady'
    }, {
        label: 'Scope of Work',
        key: 'scopeOfWorkDetail',
        type: 'textarea',
        required: true,
        placeholder: 'Cth: upload reels 1 menit tentang produk YSB Alpha Arbutin serum'
    }, {
        label: 'Kuantitas',
        key: 'quantity',
        type: 'text',
        required: true,
        placeholder: 'Cth: 1x'
    }, {
        label: 'Periode Publikasi',
        key: 'publicationPeriod',
        type: 'text',
        required: true,
        placeholder: 'Cth: Februari-Maret 2023'
    }
]

export const TERMIN_FORM_LIST = [
    {
        label: 'Termin',
        key: 'termin',
        type: 'term',
        required: false,
        disabled: true
    }, {
        label: 'Jumlah',
        key: 'payAmount',
        type: 'term',
        required: true,
        placeholder: 'Cth: 1500000'
    }, {
        label: 'Waktu Pembayaran',
        key: 'paymentState',
        type: 'term',
        required: true,
        placeholder: 'Cth: Maret 2023'
    }
]

export const SELECT_DIVISION_OBJECT = {
    label: 'Divisi',
    key: 'brand',
    type: 'select',
    options: [{
        code: 'avoskin',
        name: 'Avoskin'
    }, {
        code: 'looke',
        name: 'Looke'
    }, {
        code: 'oasea',
        name: 'Oasea'
    },]
}

export const SELECT_PUBLICATION_OBJECT = {
    label: 'Media/Media Sosial',
    key: 'socialMedia',
    type: 'select',
    options: [{
        code: 'instagram',
        name: 'Instagram'
    }, {
        code: 'tiktok',
        name: 'Tik Tok'
    }, {
        code: 'twitter',
        name: 'Twitter / X'
    }, {
        code: 'offline-event',
        name: 'Offline Event'
    }, {
        code: 'shopee',
        name: 'Shopee'
    }, {
        code: 'lazada',
        name: 'Lazada'
    },  {
        code: 'tokopedia',
        name: 'Tokopedia'
    }, {
        code: 'youtube',
        name: 'Youtube'
    }, {
        code: 'website',
        name: 'Website'
    }]
}

export const SELECT_BANK_OBJECT = {
    label: 'Nama Bank',
    key: 'bankName',
    type: 'select',
    options: bankList
}

export const STEP_LIST = ['Data KOL', 'Data Penandatangan', 'Publikasi (Table Customize)', 'Jangka Waktu & Biaya Kerja Sama'];
