import React, {useEffect, useState} from "react";
import clsx from "clsx";
import DownChevron from "./down-chev.svg"
import {useDispatch, useSelector} from "react-redux";
import {setDataForm} from "../reducers/data.reducer";

export const SelectComponent = ({item}) => {
    const dispatch = useDispatch()
    const [isShow, setShow] = useState(false)
    const {dataForm} = useSelector(state => state.dataReducer)

    useEffect(() => {
        const clickListener = (e) => {
            const flyoutElement = document.getElementById(`select-component-${item.key}`)
            let targetElement = e.target

            do {
                if (targetElement == flyoutElement) return  // This is a click inside. Do nothing, just return.
                targetElement = targetElement.parentNode
            } while (targetElement);
            setShow(false)
        }

        if (isShow) document.addEventListener("click", clickListener, true)

        return () => document.removeEventListener("click", clickListener, true)
    }, [isShow])

    const handleOnChange = (i) => {
        dispatch(setDataForm({...dataForm, [item.key]: i}))
        setShow(false)
    }

    return (
        <div id={`select-component-${item.key}`} className={clsx('relative text-sm w-full sm:max-w-[240px] sm:min-w-[200px]')}>
            <div
                className={'text-left w-full rounded-lg border py-2.5 px-4 cursor-pointer bg-white'}
                onClick={() => setShow(!isShow)}
            >
                <div className={'flex justify-between font-medium uppercase'}>
                    {dataForm[item.key] || 'Pilih'}
                    <img src={DownChevron} alt='chev-down'/>
                </div>
            </div>
            <ul className={clsx('absolute w-full bg-white whitespace-nowrap rounded-lg shadow-lg mt-2 z-10 transition-all duration-200 max-h-80 overflow-y-scroll', isShow ? 'opacity-100 scale-100 visible' : 'opacity-0 scale-95 collapse')}>
                {item.options.map((item, index) => (
                    <li
                        key={index}
                        className={'font-semibold py-3 px-4 hover:bg-gray-50 hover:text-dark-sage cursor-pointer'}
                        onClick={() => handleOnChange(item.code)}
                    >
                        {item.name}
                    </li>
                ))}

            </ul>
        </div>
    )
}
