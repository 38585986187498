const validate = (field, inputValue) => {
    const REGEX_EMAIL = /^\S+@\S+\.\S+$/
    const HTTP_REGEX = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    // const REGEX_NUMBER = /[\-.]/g;
    const REGEX_NUMBER = /^\d+$/;

    switch (field.key){
        case 'nameOfKOL':
        case 'addressKol':
        case 'signerName':
        case 'signerRole':
        case 'organizationName':
        case 'organizationAddress':
        case 'contractStartDate':
        case 'contractEndDate':
        case 'bankAccount':
        case 'bankAccountOwner':
            return inputValue.length === 0 ? `${field.label} belum diisi` : false
        case 'email':
        case 'signerEmail':
            return !REGEX_EMAIL.test(inputValue) ? `${field.label} is not valid` : false
        case 'identityCardImage':
        case 'organizationIdentityImage':
        case 'taxIdImage':
            return inputValue.length !== 0 ? !HTTP_REGEX.test(inputValue) ? `URL is not valid` : false : false
        case 'signerPhoneNumber':
            return inputValue < 10 ?`${field.label} belum lengkap` : false
        case 'identityCard':
        case 'organizationIdentityNumber':
            return inputValue.length !== 0 ? inputValue.length < 13 ? `Nomor ${field.label} belum lengkap` : inputValue.length > 16 ? `Nomor ${field.label} lebih dari 16 karakter` : false : false
        case 'NPWPNumber':
            return inputValue.length !== 0 ? !REGEX_NUMBER.test(inputValue) ? `Input NPWP hanya angka tanpa karakter` : inputValue.length !== 16 ? `Nomor ${field.label} harus 16 karakter` : false : false
        case 'contractPrice':
            return Number(inputValue) < 1 ? `${field.label} belum diisi` : false

    }
}

export default validate
