export const formModels = {
    brand: null,
    KOL_ID: 0,
    nameOfKOL: null,
    identityCard: null,
    identityCardImage: null,
    addressKol: null,
    signerName: null,
    signerRole: null,
    organizationName: null,
    organizationAddress: null,
    organizationIdentityNumber: null,
    organizationIdentityImage: null,
    signerEmail: null,
    signerPhoneNumber: null,
    contractStartDate: null,
    contractEndDate: null,
    contractPrice: 0,
    bankName: '',
    bankAccount: '',
    bankAccountOwner: '',
    timestamp: '',
    NPWPNumber:'',
    NPWPImageLink: null,
    scopeOfWorkList: [],
    termOfPaymentList: []
}

export const scopeOfWorkObj = {
    socialMediaAccount: '',
    scopeOfWorkDetail: '',
    quantity: 0,
    publicationPeriod: ''
}

export const termOfPaymentObj = {
    termin: 0,
    payAmount: 0,
    paymentState: ''
}
