import React from "react";
import {Box, Modal} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setModalSuccessVisibility} from "../../reducers/data.reducer";

export const SuccessModal = ({buttonHandler}) => {
    const dispatch = useDispatch()
    const {modalSuccessVisibility} = useSelector(state => state.dataReducer)

    const onCloseModal = () => {
        dispatch(setModalSuccessVisibility(false))
    }

    return (
        <Modal
            open={modalSuccessVisibility}
            onClose={() => onCloseModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className={"px-20 py-10 col space-y-10 items-center"}>
                    <p className={"text-2xl font-NunitoSans-Regular"}>Data Berhasil di input !!</p>
                    <button className={"button-blue"} onClick={buttonHandler}>Tambah Agreement Baru</button>
                </div>
            </Box>
        </Modal>
    )
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    outline: 'none'
};
