import React from "react";
import {Box, Modal} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setDataForm, setDataModalTermPaymentVisibility} from "../../reducers/data.reducer";
import InputComponent from "../../components/input.component";
import {checkFieldValidate} from "../../utils/general-variable";
import {scopeOfWorkObj, termOfPaymentObj} from "../../models/form.models";
import {TERMIN_FORM_LIST} from "../../utils/constant";

export const FormTermPaymentModal = () => {
    const dispatch = useDispatch()
    const {publicationItemDetail, modalTermPaymentVisibility, dataForm} = useSelector(state => state.dataReducer)

    const onCloseModal = () => {
        dispatch(setDataModalTermPaymentVisibility(false))
    }

    const onSubmitToTable = (e) => {
        e.preventDefault()
        let isValid = false
        const elements = e.target.elements
        const termOfPaymentObjCopy = {...termOfPaymentObj}
        const formTermPaymentListCopy = [...TERMIN_FORM_LIST]
        const termOfPaymentList = [...dataForm.termOfPaymentList]

        isValid = checkFieldValidate(formTermPaymentListCopy, elements, termOfPaymentObjCopy)
        if(isValid){
            termOfPaymentList[termOfPaymentObjCopy.termin - 1] = termOfPaymentObjCopy
            dispatch(setDataForm({...dataForm, ...{termOfPaymentList}}))
            onCloseModal()
        }
    }

    return (
        <Modal
            open={modalTermPaymentVisibility}
            onClose={() => onCloseModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <form onSubmit={onSubmitToTable} name={"publication"}>
                    <div className={"col items-center space-y-5 font-NunitoSans-Regular text-xs text-dark"}>
                        {
                            TERMIN_FORM_LIST.map((item, index) => <InputComponent item={item} key={index}
                                                                                     page={'publication'}/>)
                        }
                    </div>
                    <button className={"button-blue ml-56 mt-3"}>{publicationItemDetail?.socialMedia ? 'Simpan' : 'Tambah Data'}
                    </button>
                </form>
            </Box>
        </Modal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    outline: 'none',
    p: 4,
};

