import React from "react";
import InputComponent from "../../components/input.component";

const RepresentativeComponent = ({formList}) => {
    return (
            <div className={"col items-center space-y-3"}>
                {
                    formList.map((item, index) => <InputComponent item={item} key={index}/>)
                }
            </div>
    )
}

export default RepresentativeComponent
