import validate from "./validate";

const checkFieldValidate = (fieldList, elements, modelData) => {
    let isValid = true;

    fieldList.forEach((field) => {
        let result = validate(field, elements[field.key].value);
        if (result) {
            isValid = false;
            field.errorText = result
        } else {
            modelData[field.key] = elements[field.key].value
            field.errorText = null
        }
    })

    return isValid
}

const getTimestampInSeconds = () => {
    return Math.floor(Date.now() / 1000)
}

const getApiKeySheetURL = (brand) => {
    switch (brand) {
        case 'avoskin':
            return {
                url: '0eac7cfc-407f-4751-88f0-23fb3436f7e6',
                apiKey: 'NQg@xjt-aJjxvU@X63ClH$_C#-B$46MSM40P4HSagyWwIwtJuHYVziFiUHb!xJ#L',
            }
        default:
            return {
                url: 'cd7f855d-af7a-4ef1-8583-e6b22b39d69d',
                apiKey: 'Q-zMfDNwfRLbmnsuYAGFbNs!FEU@K__az#ahNYN6J9rIWd8jqbDfg6fF%weGRj0$',
            }
    }
}

const getSheetName = (brand) => {
    switch (brand) {
        case 'looke':
            return 'Looke - '
        case 'oasea':
            return 'Oasea - '
        default:
            return ''
    }
}

export {
    checkFieldValidate,
    getTimestampInSeconds,
    getApiKeySheetURL,
    getSheetName
}
