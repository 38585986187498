import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setDataForm, setDataModalTermPaymentVisibility, setDataTermOfPaymentDetail} from "../../reducers/data.reducer";
import {FormTermPaymentModal} from "./form-term-payment.modal";

const TermOfPaymentComponent = () => {
    const dispatch = useDispatch()
    const {dataForm} = useSelector(state => state.dataReducer)
    const columns = ['termin', 'payAmount', 'paymentState']

    const onEdit = (item) => {
        dispatch(setDataTermOfPaymentDetail(item))
        dispatch(setDataModalTermPaymentVisibility(true))
    }

    const onDelete = (index) => {
        let newData = dataForm.termOfPaymentList.filter((item, i) => {
            if (i !== index) {
                return true
            }
        })

        dispatch(setDataForm({...dataForm, ...{termOfPaymentList: newData}}))
    }

    const addData = () => {
        let termCount = dataForm.termOfPaymentList.length + 1
        dispatch(setDataTermOfPaymentDetail({termin: termCount, payAmount: '', paymentState: ''}))
        dispatch(setDataModalTermPaymentVisibility(true))
    }

    return (
        <div>
            <div className={"col items-center"}>
                <table>
                    <thead className={"bg-blue-100 font-NunitoSans-Bold text-fadedBlue h-8"}>
                    <tr>
                        <th className={"w-40 text-center"}>Termin</th>
                        <th className={"w-40 text-center"}>Jumlah</th>
                        <th className={"w-40 text-center"}>Waktu Pembayaran</th>
                        <th className={"w-40 text-center"}>Action</th>
                    </tr>
                    </thead>
                    <tbody className={"divide-y"}>
                    {
                        dataForm?.termOfPaymentList.map((item, index) => (
                            <tr key={index}>
                                {
                                    columns.map((i, idx) => (
                                        <td className={"text-center py-3"} key={idx + index}>{item[i]}</td>
                                    ))
                                }
                                <td>
                                    <div className={"row items-center justify-center space-x-3"}>
                                        <div className={"cursor-pointer font-bold text-darkSeaFoam"}
                                             onClick={() => onEdit(item)}>Edit
                                        </div>
                                        <div className={"cursor-pointer font-bold text-pastelRed"}
                                             onClick={() => onDelete(index)}>Delete
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
            <FormTermPaymentModal/>
        </div>
    )
}

export default TermOfPaymentComponent
