import {Route, Routes} from "react-router-dom";
import AgreementFormComponent from "../pages/agreement-form/agreement-form.component";

export default function RoutePage(){
    return(
        <Routes>
            <Route path="/" element={<AgreementFormComponent />}/>
        </Routes>
    )
}
