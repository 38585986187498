import React from "react";
import InputComponent from "../../components/input.component";
import {useDispatch, useSelector} from "react-redux";
import {checkFieldValidate} from "../../utils/general-variable";
import {Box, Modal} from "@mui/material";
import {
    setDataForm,
    setDataModalPublicationVisibility,
    setDataPublicationItemDetail
} from "../../reducers/data.reducer";
import {scopeOfWorkObj} from "../../models/form.models";
import {findIndex} from "lodash";
import {PUBLICATION_FORM_LIST, SELECT_PUBLICATION_OBJECT} from "../../utils/constant";

export const FormPublicationModal = () => {
    const dispatch = useDispatch()
    const {publicationItemDetail, modalPublicationVisibility, dataForm} = useSelector(state => state.dataReducer)

    const onSubmitToTable = (e) => {
        e.preventDefault()
        const elements = e.target.elements
        let isValid = false
        const formPublicationListCopy = [...PUBLICATION_FORM_LIST]
        const scopeOfWorkObjCopy = {...scopeOfWorkObj, socialMedia: dataForm?.socialMedia}
        const scopeOfWorkList = [...dataForm.scopeOfWorkList]

        isValid = checkFieldValidate(formPublicationListCopy, elements, scopeOfWorkObjCopy)
        isValid = Boolean(dataForm?.socialMedia)

        if (isValid) {
            if (publicationItemDetail?.id) {
                let index = findIndex(dataForm.scopeOfWorkList, (item) => item.id === publicationItemDetail?.id)
                scopeOfWorkList[index] = {...publicationItemDetail, ...scopeOfWorkObjCopy}
                dispatch(setDataForm({...dataForm, ...{scopeOfWorkList}}))
                dispatch(setDataPublicationItemDetail({}))
                onCloseModal()
            } else {
                scopeOfWorkObjCopy.id = dataForm.scopeOfWorkList.length + 1
                let scopeOfWorkList = dataForm.scopeOfWorkList.concat([scopeOfWorkObjCopy])
                dispatch(setDataForm({...dataForm, ...{scopeOfWorkList}}))
                dispatch(setDataPublicationItemDetail({}))
                onCloseModal()
            }
        }
    }

    const onCloseModal = () => {
        dispatch(setDataModalPublicationVisibility(false))
    }

    return (
        <Modal
            open={modalPublicationVisibility}
            onClose={() => onCloseModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <form onSubmit={onSubmitToTable} name={"publication"}>
                    <div className={"col items-center space-y-5 font-NunitoSans-Regular text-xs text-dark"}>
                        <InputComponent item={SELECT_PUBLICATION_OBJECT}/>
                        {
                            PUBLICATION_FORM_LIST.map((item, index) => <InputComponent item={item} key={index}
                                                                                       page={'publication'}/>)
                        }
                    </div>
                    <button
                        className={"button-blue ml-56 mt-3"}>{publicationItemDetail?.id ? 'Simpan' : 'Tambah Data'}
                    </button>
                </form>
            </Box>
        </Modal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    outline: 'none'
};
