import React, {useState} from "react";
import {Button, Step, StepLabel, Stepper} from "@mui/material";
import KolFormComponent from "./kol-form.component";
import RepresentativeComponent from "./representative.component";
import PublicationComponent from "./publication.component";
import PeriodTimeComponent from "./period-time.component";
import {useDispatch, useSelector} from "react-redux";
import {setDataForm, setLoading, setModalSuccessVisibility} from "../../reducers/data.reducer";
import {checkFieldValidate, getSheetName, getTimestampInSeconds} from "../../utils/general-variable";
import LoadingSvg from "../../utils/marcom-loading.svg"
import {KOL_FORM_LIST, PERIOD_FORM_LIST, REPRESENTATIVE_FORM_LIST, STEP_LIST} from "../../utils/constant";
import {setAgreementDataApi} from "../../api/agreement.api";
import {SuccessModal} from "./success.modal";
import {formModels} from "../../models/form.models";

const AgreementFormComponent = () => {
    const dispatch = useDispatch()
    const [activeStep, setActiveStep] = useState(0);
    const {dataReducer} = useSelector(state => state)
    const {dataForm, loading} = dataReducer
    const [formList, setFormList] = useState(KOL_FORM_LIST)
    const [representativeFormList, setRepresentativeFormList] = useState(REPRESENTATIVE_FORM_LIST)
    const [periodFormList, setPeriodFormList] = useState(PERIOD_FORM_LIST)

    const onSubmitHandler = (event) => {
        event.preventDefault()
        let isValid = false
        const formModelsCopy = {...dataForm}
        let elements = document.getElementsByName("agreement")[0].elements;

        switch (activeStep) {
            case 0:
                const fieldItemsCopy = [...formList]
                isValid = checkFieldValidate(fieldItemsCopy, elements, formModelsCopy)

                if (!isValid) {
                    setFormList(fieldItemsCopy)
                } else {
                    setActiveStep(activeStep + 1)
                }
                break;
            case 1:
                const representativeFormListCopy = [...representativeFormList]
                isValid = checkFieldValidate(representativeFormListCopy, elements, formModelsCopy)

                if (!isValid) {
                    setRepresentativeFormList(representativeFormListCopy)
                } else {
                    setActiveStep(activeStep + 1)
                }
                break;
            case 2:
                if (dataForm.scopeOfWorkList.length === 0) {
                    alert('Anda belum mengisi data KOL')
                } else {
                    isValid = true
                    setActiveStep(activeStep + 1)
                }
                break;
            case 3:
                const periodFormListCopy = [...periodFormList]
                isValid = checkFieldValidate(periodFormListCopy, elements, formModelsCopy)
                let isValidDataTerm = !(dataForm.termOfPaymentList.length === 0)

                if (!isValid || !isValidDataTerm) {
                    setPeriodFormList(periodFormListCopy)
                    if (dataForm.termOfPaymentList.length === 0) {
                        alert('Anda belum mengisi data termin')
                    }
                } else {
                    dispatch(setLoading(true))
                    let KOL_ID = getTimestampInSeconds()
                    let date = new Date()
                    let finalData = {
                        ...dataForm,
                        KOL_ID,
                        contractStartDate: `\'${dataForm?.contractStartDate}`,
                        contractEndDate: `\'${dataForm?.contractEndDate}`,
                        timestamp: date.toLocaleString("en-US", {timeZone: "Asia/Jakarta"}),
                    }

                    dispatch(setDataForm(finalData))

                    let scopeOfWork = finalData?.scopeOfWorkList.map((item) => {
                        return {...item, KOL_ID}
                    })
                    let terminKOL = finalData?.termOfPaymentList.map((item) => {
                        return {...item, KOL_ID}
                    })
                    sendAgreement(finalData?.brand, {finalData, scopeOfWork, terminKOL})
                }
                break;
        }
    }

    const sendAgreement = (brand, {finalData, scopeOfWork, terminKOL}) => {
        Promise.all([
            setAgreementDataApi(brand, finalData, `${getSheetName(brand)}Form KOL Data`),
            setAgreementDataApi(brand, scopeOfWork, `${getSheetName(brand)}SOW KOL`),
            setAgreementDataApi(brand, terminKOL, `${getSheetName(brand)}Termin KOL`)])
            .then(response => {
                dispatch(setLoading(false))
                dispatch(setModalSuccessVisibility(true))
            })
            .catch(err => {
                dispatch(setLoading(false))
                console.log(err)
            })
    }

    const formContent = [
        <KolFormComponent formList={formList}/>,
        <RepresentativeComponent formList={representativeFormList}/>,
        <PublicationComponent/>,
        <PeriodTimeComponent formList={periodFormList}/>
    ]

    const buttonHandler = () => {
        dispatch(setDataForm({...formModels}))
        dispatch(setModalSuccessVisibility(false))
        setActiveStep(0)
    }

    return (
        <div className={"h-full w-10/12 p-5"}>
            <div className={"text-dark h-full text-xs  font-NunitoSans-Regular rounded-lg"}>
                <div className={"bg-blue-100 p-5 text-dark rounded-t-lg"}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {
                            STEP_LIST.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                );
                            })
                        }
                    </Stepper>
                </div>
                {
                    loading ?
                        <div className={"w-full h-5/6 col items-center justify-center bg-blue-50 rounded-b-lg"}>
                            <img src={LoadingSvg} className={"h-10"}/>
                        </div>
                        :
                        <form name={"agreement"} className={"flex-1 flex-col h-5/6 bg-blue-50 rounded-b-lg"}>
                            <div className={"justify-between col h-full"}>
                                <div className={"py-5"}>
                                    {formContent[activeStep]}
                                </div>
                                <div className={"w-full row items-center justify-between bg-blue-100 p-5 rounded-b-lg"}>
                                    {
                                        activeStep !== 0 ?
                                            <Button onClick={() => setActiveStep(activeStep - 1)}>Prev</Button>
                                            : <div/>
                                    }
                                    <Button type={"submit"} disabled={loading}
                                            onClick={onSubmitHandler}>{activeStep === 3 ? 'Simpan' : 'Next'}</Button>
                                </div>
                            </div>
                        </form>
                }
            </div>
            <SuccessModal buttonHandler={buttonHandler}/>
        </div>
    )
}

export default AgreementFormComponent
